<template>
    <div id="tiktok-identity-form">
        <div class="top">
            <div class="top__left">
                <h2 class="mb-3">
                    Tiktok Identity
                </h2>
                <p class="desc">
                    In order to deploy ads on TikTok, businesses are required to have an “identity” for TikTok to generate a Display Name and Brand Logo on ads. Please enter the requested fields below to ensure BuyerBridge has all of the necessary information to create a TikTok identity for an account to use in any future TikTok ad deployments.
                </p>
            </div>
        </div>

        <v-form
            ref="form">
            <div class="build-details">
                <div>
                    <div
                        v-if="errorMessage"
                        class="err-message">
                        {{ errorMessage }}
                    </div>

                    <div>
                        <h2>Display Name</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Enter the name of the business that you want the TikTok ads to display.
                                    By default, we will use the account name within the BuyerBridge dashboard,
                                    but you can modify the TikTok “Display Name” at any time.
                                </p>
                            </div>
                            <div
                                v-if="!identityCompleted"
                                class="block__select">
                                <v-text-field
                                    v-model="name"
                                    maxlength="100"
                                    label="Display Name"
                                    class="styled-field"
                                    placeholder="Type name here..."
                                    required
                                    :rules="requiredRules" />
                                <div class="block__desc">
                                    {{ name.length }}/100 characters
                                </div>
                            </div>
                            <div v-else>
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div
                                v-if="!identityCompleted"
                                class="block__icons">
                                <action-button
                                    class="icon"
                                    width="23"
                                    height="23"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-tiktok-onboarding#create-tiktok-identity"
                                    target="_blank"
                                    icon="browser">
                                    <div class="dark-word sp">
                                        Support article
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5">
                        <div class="block upload-wrapper">
                            <div class="block__info">
                                <h2>Brand Logo Upload</h2>
                                <p class="desc">
                                    Upload a Brand Logo to represent the business being advertised in the TikTok Ads.
                                    The logo image uploaded must be sized appropriately, using a 1:1 (1080 x 1080 px) aspect ratio.
                                    If a brand logo of the appropriate size has already been provided, it will be pre-populated.
                                </p>
                            </div>
                            <div
                                v-if="!identityCompleted"
                                class="block__select">
                                <asset-upload
                                    :centered-text="true"
                                    :centered-title="true"
                                    :shortened="true"
                                    :initial-image="brandLogoUrl"
                                    aspect-ratio="1:1"
                                    :types="['jpeg', 'jpg', 'png', 'gif']"
                                    @asset-uploaded-url="onBrandLogoUpload" />
                            </div>
                            <div v-else>
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div
                                v-if="!identityCompleted"
                                class="block__icons">
                                <action-button
                                    class="icon"
                                    width="23"
                                    height="23"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-for-tiktok-onboarding#create-tiktok-identity"
                                    target="_blank"
                                    icon="browser">
                                    <div class="dark-word sp">
                                        Support article
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-wrapper">
                <styled-button
                    class="styled-button"
                    :disabled="notValid || loading"
                    :loading="loading"
                    @click.prevent="finishStep">
                    CONTINUE
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import validationRules from '@/helpers/validationRules';
import AssetUpload from '@/components/globals/AssetUpload';
import StatusIcon from '@/components/globals/StatusIcon';
import { isEmpty } from 'lodash';
import getMediaAspectRatio from '@/helpers/getMediaAspectRatio';

    export default {
        components: {
            ActionButton,
            StyledButton,
            AssetUpload,
            StatusIcon
        },
        data() {
            return {
                loading: false,
                name: '',
                requiredRules: validationRules.requiredRules,
                primaryLogoUrl: null,
                brandLogoUrl: null,
                accountBranding: null,
                notValid: true,
                errorMessage: null
            };
        },
        computed: {
            ...mapGetters([
                'dealerFeaturesComplete'
            ]),
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer
            }),
            existingAdAccountId() {
                return this.currentDealer.ad_accounts.data.find(a => a.platform_id == 8)?.external_ad_account_id ?? null;
            },
            identityCompleted() {
                return this.dealerFeaturesComplete.includes('tiktok_identity');
            },
        },
        watch: {
            name() {
                this.checkValidity();
            },
            brandLogoUrl() {
                this.checkValidity();
            }
        },
        async mounted() {
            this.name = this.currentDealer.name.substring(0, 100);
            await this.getAccountBranding();
            this.checkValidity();
            console.log(this.currentDealer);
        },
        methods: {
            checkValidity() {
                if(this.brandLogoUrl && this.name != '') {
                    this.notValid = false;
                } else {
                    this.notValid = true;
                }
            },
            async getAccountBranding() {
                const response = await this.$http.get('/dealers/' + this.currentDealer.id + '/account-branding');
                this.accountBranding = isEmpty(response?.data) ? null : response?.data;
                if (this.accountBranding) {
                    if (this.accountBranding.logos) {
                        for (let index in this.accountBranding.logos) {
                            if (this.accountBranding.logos[index].type == 'primary') {
                                this.primaryLogoUrl = this.accountBranding.logos[index].url;
                                const img = new Image();
                                img.src = this.primaryLogoUrl;
                                img.onload = () => {
                                    const getRatio = getMediaAspectRatio(img.width, img.height);
                                    if (getRatio == '1:1') {
                                        this.brandLogoUrl = this.primaryLogoUrl;
                                    }
                                };
                            }
                        }
                    }
                }
            },
            async onBrandLogoUpload(assetUrl, assetId) {
                this.brandLogoUrl = assetUrl;
                this.checkValidity();
                if (!this.primaryLogoUrl) {
                    this.setPrimaryLogo(assetId);
                }
            },
            async setPrimaryLogo(assetId) {
                let brandId;
                if (!this.accountBranding) {
                    brandId = await this.createBrand();
                }
                await this.$http.post('/asset_brand', {
                    asset_id: assetId,
                    brand_id: this.accountBranding?.brand_id ?? brandId,
                    asset_brand_type_id: 1
                });
            },
            async createBrand() {
                let response = await this.$http.post('/brands', {
                    dealer_id: this.currentDealer.id,
                    name: this.currentDealer.name + ' Brand',
                    public: true
                });

                await this.$http.post('/brand_dealer', {
                    dealer_id: this.currentDealer.id,
                    brand_id: response.data.data.id,
                    account_branding: true
                });

                return response.data.data.id;
            },
            async setIdentity() {
                try {
                    this.loading = true;
                    const data = {
                        url: this.brandLogoUrl,
                        display_name: this.name
                    };
                    const response = await this.$http.post(`/dealers/${this.currentDealer.id}/platforms/tiktok/ad-accounts/${this.existingAdAccountId}/identities`, data);
                    console.log(response);
                } catch (error) {
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    this.loading = false;
                }
            },
            async finishStep() {
                if (!this.identityCompleted) {
                    await this.setIdentity();
                    if (!this.errorMessage) {
                        this.$store.dispatch('goToNextStep');
                    }
                } else {
                    this.$store.dispatch('goToNextStep');
                }
            }
        }
    };
</script>

<style lang="scss">
#tiktok-identity-form{
    .top{
        display: flex;
        justify-content: space-between;
        padding-right: 60px;
    }
    .desc{
        font-size:14px;
    }
    .build-details{
        padding: 30px;
        margin-top: 15px;
        border-top: 1px solid $gray-light;
    }
    .dark-word{
        color:$gray-dark;
        font-size: 10px;
        line-height: 14px;
    }
    .icon{
        margin-left: 30px;
        margin-top: -10px;
    }
    .block{
        display: flex;
        flex-wrap: wrap;
        &__icons{
            white-space: nowrap;
            width: 10%;
        }
        &__info{
            width: 100%;
            margin-bottom: 50px;
            @media (min-width: $bp-lg) {
                width: 50%;
                margin-right: 5%;
                margin-bottom: 0;
            }
        }
        &__select{
            width: 30%;
            margin-right: 5%;
            position: relative;
        }
        &__desc{
            position: absolute;
            right:0;
            top:35px;
            font-size: 12px;
        }
    }
    .button-wrapper{
        text-align: right;

    }
    .styled-button{
        font-size: 14px;
    }
    .err-message{
        padding: 0 30px;
        margin-bottom: 30px;
        text-align: center;
        color: red;
    }
    .sp{
        padding-top:2px;
    }
    .upload-wrapper{
        display: flex;
        align-items: center;
    }
}
</style>